import React from "react"
import styled from "@emotion/styled"
import articles from "../articles-db"

const Blog = () => {
  const article = articles.find(art => art.id === "6")

  return (
    <ArticleSection>
      <ArticleContainer>
        <ArticleTextContainer>
          <ArticleTitle>{article.title}</ArticleTitle>
          <ArticleDate>Publié le {article.date}</ArticleDate>

          <ArticleImageContainer>
            <img
              src={article.image}
              alt={article.title}
              className="articleImage"
            ></img>
          </ArticleImageContainer>
          <ArticleIntro>{article.intro}</ArticleIntro>

          <ArticleText>
            Il faut savoir que les japonais sont de grands consommateurs de
            bière. Ils accompagnent leurs plats traditionnels comme les ramens
            avec une bière blonde, légère et peu alcoolisée. Elle doit être
            rafraichissante, douce, avec une légère pointe d’amertume.
          </ArticleText>

          <ArticleText>
            Pour respecter la tradition et accompagner d’une bonne bière nos
            ramens, nous avons décidé de brasser notre propre bière Hara-Kiri.
            Nous nous sommes associés à la Brasserie de l’Être, considérée comme
            l’une des meilleures de Paris. Engagée dans les circuits courts et
            respectueuse de l’environnement, elle brasse des bières artisanales
            biologiques depuis de nombreuses années.
          </ArticleText>

          <ArticleText>
            Ensemble, nous avons élaboré la recette de notre bière Hara-Kiri.
            Nous l’avons alors imaginé en harmonie avec les saveurs asiatiques,
            pour un mariage parfait avec nos plats. Nous avons choisi le houblon
            Triskel, pour ses arômes herbaux et d’agrume. Elle a ensuite été
            adoucie avec des notes de jasmin pour lui donner une saveur légère,
            subtile et florale.
          </ArticleText>

          <ArticleText>
            Artisanale, bio, légère et fruitée, la bière Hara-Kiri a été pensée
            à notre image, pour accompagner nos ramens et surtout, pour vous
            garantir un voyage immédiat vers le Japon.
          </ArticleText>
        </ArticleTextContainer>
      </ArticleContainer>
    </ArticleSection>
  )
}

const ArticleSection = styled.section`
  margin-top: 100px;
  padding: 0 10%;
`

const ArticleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`

const ArticleTextContainer = styled.div`
  width: 80%;
  /* padding: 20px 60px; */
  margin: auto;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 20px;
  }
`

const ArticleTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
`
const ArticleDate = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
`
const ArticleIntro = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`

const ArticleText = styled.p`
  font-size: 14px;
  margin-bottom: 40px;
`

const ArticleImageContainer = styled.div`
  width: 100%;
  height: 500px;
  background-color: #fff;
  margin-bottom: 40px;

  .articleImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 640px) {
    width: 80vw;
    height: 80vw;
  }
`

export default Blog
