import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Article6 from "../components/Article6"

const IndexPage = () => (
  <Layout headerColor={"black"}>
    <SEO title="Hara-Kiri brasse sa propre bière" />
    <Article6 />
  </Layout>
)

export default IndexPage
